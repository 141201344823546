import React from 'react';
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';

interface ContactDetail {
  icon: JSX.Element;
  title: string;
  description: string;
  href: string;
}

const ContactUs: React.FC = () => {
  const contactDetails: ContactDetail[] = [
    {
      icon: <FaMapMarkerAlt />,
      title: 'Address',
      description: 'West Midlands',
      href: 'https://maps.google.com/?q=West Midlands, England'
    },
    {
      icon: <FaPhone />,
      title: 'Phone Number',
      description: '+447930030224',
      href: 'tel:+447930030224'
    },
    {
      icon: <FaEnvelope />,
      title: 'Email Address',
      description: 'thepatientcentre@gmail.com',
      href: 'mailto:thepatientcentre@gmail.com'
    }
  ];

  return (
    <div className='w-[80%] mx-auto h-fit py-20'>
      <p className='text-center tracking-widest font-medium ml-1 text-[#00a7cc]'>CONTACT US</p>
      <p className='text-center font-bold text-6xl'>We are here to help you!</p>
      <p className='tracking-wide ml-1 mt-5 mb-12 text-center'>
      If you need assistance or wish to collaborate, please reach out to us via email. Our team is here to support your needs and explore partnerships that advance healthcare solutions. Let's work together to make a positive impact!
      </p>
      <div>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8 w-[60%] mx-auto'>
          {contactDetails.map((item, index) => (
            <a
              key={index}
              href={item.href}
              target={item.title === 'Address' ? '_blank' : '_self'}
              rel='noopener noreferrer'
              className='bg-white p-6 flex flex-col items-center hover:shadow-lg transition-shadow duration-200'>
              <div className='text-[#00a7cc] text-4xl mb-4'>{item.icon}</div>
              <p className='font-bold'>{item.title}</p>
              <p className='text-center mt-2'>{item.description}</p>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
