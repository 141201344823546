import React from 'react'

const AboutUs: React.FC = () => {
    return (
        <div className='bg-[#c5e3ec]'>
            <div className='flex flex-col md:flex-row justify-between py-20 mx-auto w-[90%] lg:w-[80%]'>
                <div className='flex-1 order-2 md:order-1'>
                    <div className='tracking-widest font-medium ml-1 text-[#00a7cc]'>ABOUT US</div>
                    <div className='font-bold text-4xl md:text-6xl mb-6'>Connecting Patients to Trusted Healthcare Resources</div>
                    <div className='tracking-wide text-justify md:text-left ml-1'>
                        ThePatientCentre.com, founded by Dr. Ali Sidat, connects patients to essential services like charities, healthcare facilities, and trusted resources. With the NHS under increasing pressure, the platform promotes healthier lifestyles and disease prevention, offering access to longstanding healthcare charities.
                        Patients can also find clear information about their conditions, treatment options, and safety advice. A medication tool provides details on prescribed drugs, and a service locator helps users find nearby pharmacists, dentists, and opticians.
                        We are constantly improving and welcome your feedback via the Contact Us button.
                        Disclaimer: Not for emergency use.
                    </div>
                </div>
                <div className='flex-1 w-full flex justify-center items-center order-1 mb-10 md:mb-0 md:order-2'>
                    <div className='relative bg-white h-80 w-full md:w-80 rounded-xl'>
                        <img src='/about.png' alt='About Us' className='absolute top-2 md:top-4 left-2 md:left-4 h-80 w-full md:w-80 object-center object-cover rounded-xl' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs