export const hospitalData = [
    {
        name: "Addenbrooke's Hospital",
        phone: "01223 257212 (Adult) / 01223 274757 (Paediatric)",
        hours: "24hr voicemail",
        voicemail: "Yes",
        email: "paediatricibd@addenbrookes.nhs.uk",
        responseTime: "48 hours",
        address: "Addenbrooke's Hospital, Hills Road, Cambridge\nCB2 0QQ",
        healthBoard: "Cambridge University Hospitals NHS Trust"
    },
    {
        name: "Alder Hey Children's Hospital",
        phone: "01512 525190",
        hours: "Mon 10am-12pm, Wed 11am-12pm, Thurs 9.30am-11pm with 24hr voicemail",
        voicemail: "Yes",
        email: "ahc-tr.ibdnurses@nhs.net",
        responseTime: "Within 24 hours",
        address: "Eaton Road, Liverpool\nL12 2AP",
        healthBoard: "Alder Hey Childrens NHS Foundation Trust"
    },
    {
        name: "Barnet Hospital",
        phone: "0208 216 4761",
        hours: "8am–5pm Mon to Fri",
        voicemail: "Yes",
        email: "rf.bcfh-ibdnurses@nhs.net",
        responseTime: "Within 48 hours",
        address: "Barnet Hospital\nWellhouse Lane\nBarnet\nEN5 4DJ",
        healthBoard: "Royal Free London NHS Foundation Trust"
    },
    {
        name: "Basildon University Hospital",
        phone: "01268 524900 ext. 1499",
        hours: "8am - 6pm",
        voicemail: "Yes",
        email: "ibd.adviceteam@btuh.nhs.uk",
        responseTime: "Within 24 hours",
        address: "Nethermayne, Basildon\nSS16 5NL",
        healthBoard: "Basildon and Thurrock University Hospital"
    },
    {
        name: "Bedford Hospital",
        phone: "01234 355122 ext 6204",
        hours: "8am - 4pm, Mon - Fri",
        voicemail: "Yes",
        email: "ibdnurse@bedfordhospital.nhs.uk",
        responseTime: "Within 48 hours",
        address: "Kempston Road\nMK42 9DJ",
        healthBoard: "Bedford NHS Trust"
    },
    {
        name: "Bristol Royal Infirmary",
        phone: "01173 421100",
        hours: "8am - 4pm",
        voicemail: "Yes",
        email: "ibdnurses@uhbristol.nhs.uk",
        responseTime: "Within 2 working days (Please leave indication of urgency)",
        address: "Marlborough Street\nBS2 8HW",
        healthBoard: "University Hospitals Bristol NHS Foundation Trust"
    },
    {
        name: "Broomfield Hospital",
        phone: "01245 514705",
        hours: "Monday, Tuesday, Friday: 9am-11am. Wednesday: 8:30am-11:30am and 2:30pm-4:30pm, Thursday: 1pm",
        voicemail: "No",
        email: "mse.meht-ibd-advice@nhs.net",
        responseTime: "No voicemail service",
        address: "Court Road\nCM1 7ET",
        healthBoard: "MEHT"
    },
    {
        name: "Chelsea and Westminster Hospital",
        phone: "0203 315 3555",
        hours: "Mon-Fri",
        voicemail: "Yes",
        email: "caw-tr.ibdteam@nhs.net",
        responseTime: "Within 48 hours",
        address: "369 Fulham Road\nSW10 9NH",
        healthBoard: "Chelsea and Westminster Hospital NHS Foundation Trust"
    },
    {
        name: "Chesterfield Royal Hospital",
        phone: "01246 512884",
        hours: "9am - 5pm, Mon - Fri",
        voicemail: "Yes",
        email: "crhft.ibdadvice@nhs.net",
        responseTime: "24 - 72 hrs",
        address: "Calow, Chesterfield, Derbyshire\nS44 5BL",
        healthBoard: ""
    },
    {
        name: "Croydon University Hospital",
        phone: "020 8401 3000 ext 4484",
        hours: "7am - 5pm, Mon - Thurs",
        voicemail: "Yes",
        email: "ch-tr.ibdcuh@nhs.net",
        responseTime: "24-72 hrs",
        address: "530 London Road, Croydon\nCR7 7YE",
        healthBoard: "Croydon Health Services NHS Trust"
    },
    {
        name: "Darent Valley Hospital",
        phone: "Not available",
        hours: "No",
        voicemail: "No",
        email: "dgn-tr.dvhibdadviceline@nhs.net",
        responseTime: "Response within 2 working days",
        address: "Darent Valley Hospital\nDarenth Wood Road\nKent\nDA2 8DA",
        healthBoard: "Dartford and Gravesham NHS Trust"
    },
    {
        name: "Darlington Memorial Hospital",
        phone: "01325 743434",
        hours: "9am - 5pm, Mon to Fri",
        voicemail: "Yes",
        email: "sritchie4@nhs.net",
        responseTime: "Within 24 hours",
        address: "Hollyhurst Road\nDL3 6HX",
        healthBoard: "County Durham and Darlington NHS Foundation Trust"
    },
    {
        name: "Derriford Hospital",
        phone: "01752 439224",
        hours: "Monday- Friday 0800-1630hrs",
        voicemail: "Yes",
        email: "plh-tr.ibd-advice@nhs.net",
        responseTime: "24 hours",
        address: "Level 7 Derriford Plymouth\nPL6 8DH",
        healthBoard: "Plymouth Hospital NHS Trust"
    },
    {
        name: "Dorset County Hospital",
        phone: "01305 255102",
        hours: "9am - 4pm, Mon - Fri",
        voicemail: "Yes",
        email: "ibdnurses@dchft.nhs.uk",
        responseTime: "Within 48 hours",
        address: "Medical Outpatients\nWilliams Avenue\nDT1 2JY",
        healthBoard: "Dorset County Hospital Foundation Trust"
    },
    {
        name: "Frimley Park Hospital",
        phone: "01276 526953",
        hours: "10am - 12pm, Wed & Thurs",
        voicemail: "No",
        email: "frimleyibd@fhft.nhs.uk",
        responseTime: "Within 48 hours",
        address: "Gastroenterology\nPortsmouth rd, Frimley, Surrey\nGU16 7UJ",
        healthBoard: "Frimley health"
    },
    {
        name: "George Eliot Hospital",
        phone: "02476 865364",
        hours: "9am - 5pm, Mon - Fri",
        voicemail: "Yes",
        email: "daljit.chohan@geh.nhs.uk",
        responseTime: "Within 24 hours",
        address: "Medicine - Gastroenterology\nCollege Street, Nuneaton\nCV10 7DJ",
        healthBoard: "George Eliot Hospital"
    },
    {
        name: "Glasgow Royal Infirmary",
        phone: "0141 201 6542",
        hours: "Monday-Friday (not public holidays)",
        voicemail: "Yes",
        email: "ibdnursesgri@ggc.Scot.nhs.uk",
        responseTime: "72 hours",
        address: "Gastroenterology\n84 Castle ST\nGlasgow\nG40SF",
        healthBoard: "NHS Greater Glasgow and Clyde"
    },
    {
        name: "Gloucestershire Royal Hospital",
        phone: "0300 422 2475",
        hours: "9am - 5pm, Mon - Fri",
        voicemail: "Yes",
        email: "gastro.nurses@glos.nhs.uk",
        responseTime: "1 working day",
        address: "Great Western Road\nGL1 3NN",
        healthBoard: "Gloucestershire Hospitals NHS Foundation Trust"
    },
    {
        name: "Good Hope Hospital",
        phone: "01214 249687",
        hours: "8am- 4pm Mon -Fri",
        voicemail: "Yes",
        email: "ibdnurses.ghh@uhb.nhs.uk",
        responseTime: "Within 2 working days",
        address: "Rectory Road Sutton Coldfield West Mids\nB75 7RR",
        healthBoard: "Heart of England Foundation Trust"
    },
    {
        name: "Guy's and St Thomas' NHS Foundation Trust",
        phone: "020 7188 2487",
        hours: "24hr voicemail",
        voicemail: "Yes",
        email: "ibdhelpline@gstt.nhs.uk",
        responseTime: "Within 1 working day, excluding weekends",
        address: "1st floor College House, Westminster Bridge Road, London\nSE1 9RT",
        healthBoard: "Guy's and St Thomas' NHS Foundation Trust"
    },
    {
        name: "Hillingdon Hospital",
        phone: "07768121713 / 01895279812",
        hours: "Mon - Fri 09:00-16:00 (excluding bank holidays)",
        voicemail: "Yes",
        email: "thh.ibdsos@nhs.net",
        responseTime: "Within 48 hours",
        address: "Pield Heath Road\nUxbridge\nUB8 3NN",
        healthBoard: "The Hillingdon Hospitals NHS Trust"
    },
    {
        name: "Homerton University Hospital",
        phone: "0208 510 5906",
        hours: "48-72 hours",
        voicemail: "Yes",
        email: "huh-tr.homertonibdcns@nhs.net",
        responseTime: "48-72 hours",
        address: "Homerton Row\nE9 6SR",
        healthBoard: "Homerton University Hospital NHS Trust"
    },
    {
        name: "Huddersfield Royal Infirmary",
        phone: "01484 355370",
        hours: "8am - 12pm",
        voicemail: "Yes",
        email: "ibd.nurses@cht.nhs.uk",
        responseTime: "Within 24 hours, excluding weekends",
        address: "Acre Street, Lindley\nHD3 3EA",
        healthBoard: "Huddersfield & Calderdale Foundation Trust"
    },
    {
        name: "John Radcliffe Hospital",
        phone: "01865 228772",
        hours: "8am - 6pm Mon to Fri",
        voicemail: "Yes",
        email: "ibd.advice@nhs.net",
        responseTime: "Within 1 working day",
        address: "John Radcliffe Hospital, Headley Way, Oxford\nOX3 9DU",
        healthBoard: "Oxford University Hospitals NHS Foundation Trust"
    },
    {
        name: "Kingston Hospital",
        phone: "0208 934 2760",
        hours: "Answerphone",
        voicemail: "Yes",
        email: "khft.ibdadviceline@nhs.net",
        responseTime: "48 hours Mon-Fri",
        address: "Galsworthy Road, Kingston Upon Thames\nKT2 7QB",
        healthBoard: "Kingston NHS Trust"
    },
    {
        name: "Leighton Hospital",
        phone: "01625 661653",
        hours: "Answerphone",
        voicemail: "Yes",
        email: "ecn-tr.MaccIBD@nhs.net",
        responseTime: "As soon as possible",
        address: "Middlewich Road\nCrewe\nCW1 4QJ",
        healthBoard: "East Cheshire Hospitals NHS Trust"
    },
    {
        name: "Lincoln County Hospital",
        phone: "01522 512512 ext 582006 / Lead IBD Advanced Nurse Practitioner: 07717 301636",
        hours: "Mon-Friday",
        voicemail: "Yes",
        email: "ibd.nurses@ulh.nhs.uk",
        responseTime: "Within 72 hours",
        address: "Greetwell Road\nLN2 5QY",
        healthBoard: "United Lincolnshire Hospitals NHS Trust"
    },
    {
        name: "Lister Hospital",
        phone: "Not available",
        hours: "Not available",
        voicemail: "Not available",
        email: "ibdhelpline.enh-tr@nhs.net",
        responseTime: "Not available",
        address: "Coreys Mill Lane, Stevenage, Herts.\nSG1 4AB",
        healthBoard: "East and North Herts NHS Trust"
    },
    {
        name: "Luton and Dunstable University Hospital",
        phone: "01582 718368 / IBD patients can also contact Supported by Patients Know Best on 0800 088 5827 or 01223 790708",
        hours: "Voicemail",
        voicemail: "Yes",
        email: "ibd@ldh.nhs.uk",
        responseTime: "As soon as possible",
        address: "Lewsey Road, Luton, Bedfordshire\nLU4 0DZ",
        healthBoard: "Bedfordshire Hospitals NHS Foundation Trust"
    },
    {
        name: "Macclesfield District General Hospital",
        phone: "01625 661653",
        hours: "Voicemail",
        voicemail: "Yes",
        email: "ecn-tr.MaccIBD@nhs.net",
        responseTime: "As soon as possible",
        address: "First Floor, Silk House, Hope Drive, Macclesfield\nSK10 3BL",
        healthBoard: "East Cheshire NHS Trust"
    },
    {
        name: "Maidstone Hospital",
        phone: "01622 224084",
        hours: "Mon-Fri 9am-5pm",
        voicemail: "Yes",
        email: "mtw-tr.ibdnursetwh@nhs.net",
        responseTime: "Within three working days",
        address: "Hermitage Lane\nME16 9QQ",
        healthBoard: "Maidstone and Tunbridge Wells NHS Trust"
    },
    {
        name: "Manchester Royal Infirmary",
        phone: "0161 276 4168",
        hours: "Voicemail",
        voicemail: "Yes",
        email: "ibd@mft.nhs.uk",
        responseTime: "As soon as possible",
        address: "Oxford Road\nM13 9WL",
        healthBoard: "Manchester University NHS Foundation Trust"
    },
    {
        name: "Milton Keynes University Hospital",
        phone: "01908 996 955",
        hours: "8.30am -1.30pm, Mon - Fri",
        voicemail: "Yes",
        email: "IBDNursingteam@mkuh.nhs.uk",
        responseTime: "Within three working days",
        address: "Standing Way, Eaglestone, Milton Keynes\nMK6 5LD",
        healthBoard: "Milton Keynes University Hospital NHS Foundation Trust"
    },
    {
        name: "Neath Port Talbot Hospital",
        phone: "01639 862551",
        hours: "24 hour voicemail",
        voicemail: "Yes",
        email: "russell.thomas@wales.nhs.uk",
        responseTime: "Within 24 hours",
        address: "Baglan Way, Port Talbot\nSA12 7BX",
        healthBoard: "ABMU"
    },
    {
        name: "Nevil Hall Hospital",
        phone: "01633 656055",
        hours: "9am - 5pm, Mon-Fri",
        voicemail: "Yes",
        email: "ibdhelp@wales.nhs.uk",
        responseTime: "Within 1 working day",
        address: "Brecon Road, Abergavenny\nNP7 7EG",
        healthBoard: "Aneurin Bevan University Health Board"
    },
    {
        name: "New Cross Hospital",
        phone: "01902 694077",
        hours: "9am - 4pm",
        voicemail: "Yes",
        email: "rwh-tr.ibdsupportrwht@nhs.net",
        responseTime: "Within 48 hours",
        address: "Wolverhampton Road\nWV10 0QP",
        healthBoard: "The Royal Wolverhampton NHS Trust"
    },
    {
        name: "North Manchester General Hospital",
        phone: "N/A",
        hours: "N/A",
        voicemail: "N/A",
        email: "nmgh.ibd@mft.nhs.uk",
        responseTime: "Within 2 working days",
        address: "Delaunays Road, Crumpsall, Manchester\nM8 5RB",
        healthBoard: "Manchester University NHS Foundation Trust"
    },
    {
        name: "Northampton General Hospital",
        phone: "01604 545738",
        hours: "1:30pm - 4pm",
        voicemail: "No",
        email: "ibdadvice.ngh@nhs.net",
        responseTime: "Within 72 hours",
        address: "Cliftonville, Northampton\nNN1 5BD",
        healthBoard: "Northampton General Hospital NHS Trust"
    },
    {
        name: "Nottingham City Hospitals NHS Trust",
        phone: "01158 402633",
        hours: "7am - 12pm, with 24hr voicemail",
        voicemail: "Yes",
        email: "ibdhelpline@nuh.nhs.uk",
        responseTime: "Within 1 working day",
        address: "Hucknall Road, Nottingham\nNG5 1PB",
        healthBoard: "Nottingham University Hospital"
    },
    {
        name: "Queen Elizabeth Hospital, Birmingham",
        phone: "01213 715905",
        hours: "24 hour voicemail",
        voicemail: "No",
        email: "ibdhelpline@uhb.nhs.uk",
        responseTime: "Within 1 working day",
        address: "Level 7, Area 6, QEHB, Mindelsohn Way, Edgbaston, Birmingham\nB15 2GW",
        healthBoard: "University Hospitals Birmingham NHS Foundation Trust"
    },
    {
        name: "Queens Hospital, Burton-on-Trent",
        phone: "01332 511511 ext 5854",
        hours: "Not available",
        voicemail: "Not available",
        email: "dhft.ibdcns@nhs.net",
        responseTime: "As soon as possible",
        address: "Queens Hospital Burton\nBelvedere Road\nBurton-on-Trent\nDE13 0RB",
        healthBoard: "University Hospitals of Derby and Burton NHS Trust"
    },
    {
        name: "Royal Alexandra Hospital",
        phone: "01413 146756",
        hours: "24 hours voicemail",
        voicemail: "Yes",
        email: "ggc.ibdmailrah@nhs.scots",
        responseTime: "Within 1 working day",
        address: "Corsebar Road\nPA2 9PN",
        healthBoard: "Greater Glasgow and Clyde"
    },
    {
        name: "Royal Blackburn Hospital",
        phone: "Currently inactive. Please contact your consultant's secretary",
        hours: "N/A",
        voicemail: "N/A",
        email: "N/A",
        responseTime: "N/A",
        address: "Haslingden Road, Blackburn\nBB2 3HH",
        healthBoard: "East Lancashire Hospitals NHS Trust"
    },
    {
        name: "Royal Bolton Hospital",
        phone: "01204 390210",
        hours: "11am - 12pm Mon - Fri",
        voicemail: "Yes",
        email: "ibdflareline@boltonft.nhs.uk",
        responseTime: "Within 24 hours",
        address: "Minerva Road, Bolton\nBL4 0JR",
        healthBoard: "Bolton NHSFT"
    },
    {
        name: "Royal Bournemouth Hospital",
        phone: "0300 019 4191",
        hours: "8am - 4pm, Mon - Fri (excluding bank holidays)",
        voicemail: "Yes",
        email: "ibdhelpdesk@uhd.nhs.uk",
        responseTime: "Within 2 working days",
        address: "Castle Lane East\nBournemouth\nDorset\nBH7 7DW",
        healthBoard: "Royal Bournemouth and Christchurch Hospitals NHS Trust"
    },
    {
        name: "Royal Cornwall Hospital",
        phone: "01872 252178",
        hours: "09:00 - 12:30 Monday - Friday",
        voicemail: "Yes",
        email: "rch-tr.IBDnurse@nhs.net",
        responseTime: "As soon as possible",
        address: "Treliske\nTruro\nCornwall\nTR1 3LQ",
        healthBoard: "Royal Cornwall Hospital NHS Trust"
    },
    {
        name: "Royal Derby Hospital",
        phone: "01332 785504",
        hours: "24/7 (Answerphone Service)",
        voicemail: "Yes",
        email: "dhft.ibdadvice@nhs.net",
        responseTime: "2-5 working days",
        address: "Royal Derby Hospital\nUttoxeter Road\nDerby\nDE22 3NE",
        healthBoard: "University Hospitals of Derby and Burton NHS Trust"
    },
    {
        name: "Royal Devon and Exeter Hospital",
        phone: "01392 402728",
        hours: "9am - 5pm Mon - Fri",
        voicemail: "Yes",
        email: "rde-tr.ibdhelpline@nhs.net",
        responseTime: "Within 1 working day",
        address: "Barrack Road, Exeter\nEX2 5DW",
        healthBoard: ""
    },
    {
        name: "Royal Free Hospital",
        phone: "020 7830 2283",
        hours: "9am - 5pm, Mon - Fri",
        voicemail: "Yes",
        email: "rf.ibdnurses@nhs.net",
        responseTime: "Within 24 hours",
        address: "8 south offices, 8th Floor, Pond Street, London\nNW3 2QG",
        healthBoard: "Royal Free Hospital, London"
    },
    {
        name: "Royal Gwent Hospital",
        phone: "01633 656055",
        hours: "9am - 5pm, Mon - Fri",
        voicemail: "Yes",
        email: "ibdhelp@wales.nhs.uk",
        responseTime: "48-72 hours",
        address: "Cardiff Rd, Newport, Gwent\nNP20 2UB",
        healthBoard: "Aneurin Bevan University Health Board"
    },
    {
        name: "Royal Hallamshire Hospital",
        phone: "01142 712209",
        hours: "Mon & Tues 2pm - 4pm, Weds - Fri 10am - 12pm",
        voicemail: "Yes",
        email: "ibdnurse@sth.nhs.uk",
        responseTime: "Within 24 hours",
        address: "Glossop Road, Sheffield\nS10 2JF",
        healthBoard: "Sheffield Teaching Hospitals"
    },
    {
        name: "Royal Hospital for Children, Glasgow",
        phone: "1414524901",
        hours: "09:00 - 17:00 (voicemail)",
        voicemail: "Yes",
        email: "ggc.ibdnursesrhcglasgow@nhs.scot",
        responseTime: "2 working days",
        address: "Paediatric Gastroenterology\nRoyal Hospital for Children\n1345 Govan Rd\nGlasgow\nG51 4TF",
        healthBoard: "NHS Greater Glasgow and Clyde"
    },
    {
        name: "Royal Liverpool Hospital",
        phone: "0151 706 2659",
        hours: "24 hours",
        voicemail: "Yes",
        email: "iBDNurses@rlbuht.nhs.uk",
        responseTime: "Within 48 hours",
        address: "Royal Liverpool Hospital\nPrescott Street\nLiverpool\nL7 8XP",
        healthBoard: "Royal Liverpool and Broadgreen University NHS Hospital Trust"
    },
    {
        name: "Royal London Children's Hospital",
        phone: "020 3594 3700",
        hours: "9am - 4pm, Mon-Fri",
        voicemail: "Yes",
        email: "ibdhelpline@bartshealth.nhs.uk",
        responseTime: "Not specified",
        address: "Whitechapel Road\nE1 1FR",
        healthBoard: ""
    },
    {
        name: "Royal Surrey County Hospital",
        phone: "01483 571122 ext 2423",
        hours: "24 hour voicemail",
        voicemail: "Yes",
        email: "rsch.ibdnurses@nhs.net",
        responseTime: "Within 2 working days",
        address: "Egerton Road\nGuildford\nSurrey\nGU2 7XX",
        healthBoard: ""
    },
    {
        name: "Royal Sussex County Hospital",
        phone: "01273 664427",
        hours: "9am - 5pm, Mon - Fri",
        voicemail: "Yes",
        email: "uhsussex.ibdnursingservice@nhs.net",
        responseTime: "Within 24 hours",
        address: "Eastern Road\nBN2 5BE",
        healthBoard: "Brighton and Sussex University Hospitals NHS Trust"
    },
    {
        name: "Royal Victoria Hospital",
        phone: "028 9063 9519",
        hours: "9am - 4pm, Mon - Fri",
        voicemail: "Yes",
        email: "ibdnurse@belfasttrust.hscni.net",
        responseTime: "Within 24 hours",
        address: "Ambulatory Care Centre\nLevel 1, RVH, Grosvenor Road, Belfast\nBT12 6BA",
        healthBoard: "Belfast Health and Social Care Trust"
    },
    {
        name: "Russells Hall Hospital",
        phone: "01384 456111 ext 2440",
        hours: "9am - 1pm, with 24hr voicemail",
        voicemail: "Yes",
        email: "dudley.ibdnurses@nhs.net",
        responseTime: "Within 24 hours, excluding weekends",
        address: "IBD Office C7, Russells Hall Hospital, Pensnett Road, Dudley\nDY1 2HQ",
        healthBoard: "The Dudley Group Foundation Trust"
    },
    {
        name: "Salisbury District Hospital",
        phone: "01722 336262 Ext 4893",
        hours: "9am to 4pm Monday to Friday excluding bank holidays",
        voicemail: "Yes",
        email: "shc-tr.ibdnurses@nhs.net",
        responseTime: "Within 5 working days",
        address: "Odstock Road\nSP2 8BJ",
        healthBoard: ""
    },
    {
        name: "Salford Royal Hospital",
        phone: "1612064023",
        hours: "Monday, Tuesday, Thursday and Friday 7.30am-10am",
        voicemail: "Yes",
        email: "IBD2@nca.nhs.uk",
        responseTime: "Within 72 hours",
        address: "Stott Lane\nSalford\nManchester\nM6 8HD",
        healthBoard: "Salford Royal Hospitals NHS Trust"
    },
    {
        name: "Sandwell General Hospital",
        phone: "0121 5075822",
        hours: "24 hour voicemail",
        voicemail: "Yes",
        email: "heather.hart@nhs.net",
        responseTime: "Within 2 working days",
        address: "Lyndon, West Bromwich\nB71 4HJ",
        healthBoard: "Sandwell and West Birmingham Hospitals NHS Trust"
    },
    {
        name: "Sheffield Children's Hospital",
        phone: "01142 717111",
        hours: "9am - 3pm, Mon - Fri",
        voicemail: "Yes",
        email: "gastronurses@sch.nhs.uk",
        responseTime: "Within 24 hours",
        address: "Western Bank, Sheffield\nS10 2TH",
        healthBoard: "Sheffield Children's Hospital"
    },
    {
        name: "South Tyneside District Hospital",
        phone: "01914 041000 ext 2274",
        hours: "8am - 4pm, Mon - Fri",
        voicemail: "Yes",
        email: "emily.hopkins@stft.nhs.uk",
        responseTime: "Within 48 hours",
        address: "Harton Lane, South Shields\nNE34 0PL",
        healthBoard: "South Tyneside NHS Foundation Trust"
    },
    {
        name: "Southend University Hospital",
        phone: "01702 453333 ext 5675",
        hours: "24 hour voicemail",
        voicemail: "Yes",
        email: "ibd.helpline@southend.nhs.uk",
        responseTime: "Within 48 hours",
        address: "Prittlewell Chase, Westcliff-on-Sea\nSS0 0RY",
        healthBoard: ""
    },
    {
        name: "Southmead Hospital",
        phone: "01174 146354",
        hours: "9am - 5pm, Mon - Fri",
        voicemail: "Yes",
        email: "Ibdnurses@nbt.nhs.uk",
        responseTime: "Within 48 hours",
        address: "Level 6, Gate 10, Brunel Building, Southmead Hospital, Westbury-on-Trym, Bristol\nBS10 5NB",
        healthBoard: "North Bristol NHS Trust"
    },
    {
        name: "Southport and Formby District General Hospital",
        phone: "01704 704093",
        hours: "8.30am - 4.30pm, Mon - Fri",
        voicemail: "Yes",
        email: "soh-tr.ibd-advice@nhs.net",
        responseTime: "Within 48 hours",
        address: "Town Lane, Kew, Southport\nPR8 6PN",
        healthBoard: "Southport and Ormskirk Hospital NHS Trust"
    },
    {
        name: "St George's Hospital",
        phone: "Advice line is manned Mon-Fri (not at weekends or BHs). IBD Secretary 020 8266 6178.",
        hours: "N/A",
        voicemail: "No",
        email: "ibdadviceline@stgeorges.nhs.uk",
        responseTime: "Response time: 48 hours",
        address: "Blackshaw Road\nTooting\nLondon\nSW17 0QT",
        healthBoard: "St George's University Hospitals NHS Trust"
    },
    {
        name: "St Helier Hospital",
        phone: "020 8296 2340",
        hours: "9am - 5pm",
        voicemail: "Yes",
        email: "ugi-sth@nhs.net",
        responseTime: "Within 48 hours",
        address: "Wrythe Lane, Carshalton\nSM5 1AA",
        healthBoard: "Epsom & St Helier Hospital NHS Trust"
    },
    {
        name: "St Peters Hospital",
        phone: "01932 722244",
        hours: "9 am - 12 pm, Mon, Weds, Fri",
        voicemail: "Yes",
        email: "asp-tr.gastro.secretaries@nhs.net",
        responseTime: "Within 48 hours",
        address: "Guildford Road, Chertsey, Surrey\nKT16 0PZ",
        healthBoard: "Ashford and St Peter's NHS Trust"
    },
    {
        name: "St Thomas Hospital",
        phone: "0207 188 2487",
        hours: "Voicemail",
        voicemail: "Yes",
        email: "ibdhelpline@gstt.nhs.uk",
        responseTime: "1-2 working days",
        address: "1st Floor College House\nSE1 7EH",
        healthBoard: "Guy's and St Thomas' NHS Foundation Trust"
    },
    {
        name: "Tameside General Hospital",
        phone: "01619 226546",
        hours: "8am - 6pm, Mon - Fri",
        voicemail: "Yes",
        email: "ibdnurseservice@tgh.nhs.uk",
        responseTime: "Within 1 working day",
        address: "Fountain Street\nOL6 9RW",
        healthBoard: ""
    },
    {
        name: "The Great North Children's Hospital",
        phone: "01912 824087",
        hours: "9am - 4pm, Mon - Fri",
        voicemail: "Yes",
        email: "tnu-tr.childrensibdnurse@nhs.net",
        responseTime: "Within 48 hours",
        address: "Children's Directorate\nCRB, Office Block 2, Level 3, RVI, Queen Victoria Road, Newcastle upon Tyne\nNE1 4LP",
        healthBoard: "The Newcastle upon Tyne Hospitals Foundation Trust"
    },
    {
        name: "The Royal Stoke County Hospital (Paediatrics)",
        phone: "01782 675162",
        hours: "8:30am - 5pm, Mon - Fri",
        voicemail: "Yes",
        email: "janis.maginnis@uhns.nhs.uk",
        responseTime: "Within 24 hours, excluding weekends",
        address: "Paediatric Gastroenterology\nWard 217, Childrens Centre, Newcastle Road, Newcastle\nST16 3SA",
        healthBoard: "The University Hospitals of North Midlands"
    },
    {
        name: "The Royal Stoke University Hospital, County Hospital, and University Hospital of North Midlands",
        phone: "01782 675360",
        hours: "09:00 - 14:00",
        voicemail: "Yes",
        email: "ibd.nursingteam@nhs.net",
        responseTime: "3 days for non-urgent, 24 hours for urgent",
        address: "Newcastle Road\nStoke-On-Trent\nST4 6QG",
        healthBoard: "University Hospitals of North Midlands NHS Trust"
    },
    {
        name: "Torbay Hospital",
        phone: "01803 655111",
        hours: "9am - 5pm, Mon - Fri",
        voicemail: "Yes",
        email: "ibdtorbay.sdhct@nhs.net",
        responseTime: "Within 24 hours",
        address: "Lowes Bridge, Torquay\nTQ2 7AA",
        healthBoard: "Torbay and South Devon NHS Foundation Trust"
    },
    {
        name: "Tunbridge Wells Hospital",
        phone: "01892 635137",
        hours: "Mon-Fri 9am-5pm",
        voicemail: "Yes",
        email: "mtw-tr.ibdnursetwh@nhs.net",
        responseTime: "Within three working days",
        address: "Tunbridge Wells Hospital, Tonbridge Road, Pembury, Tunbridge Wells, Kent\nTN2 4QJ",
        healthBoard: "Maidstone & Tunbridge Wells NHS Trust"
    },
    {
        name: "University Hospital Coventry",
        phone: "02476966075",
        hours: "08:00-15:00",
        voicemail: "No",
        email: "uhc-tr.uhcwibdnurse@nhs.net",
        responseTime: "48 hours",
        address: "University Hospital Coventry\nClifford Bridge Road\nCoventry\nCV2 2DX",
        healthBoard: "University Hospitals Coventry and Warwickshire NHS Trust"
    },
    {
        name: "University Hospital Lewisham",
        phone: "07500 559478",
        hours: "9am-3.30pm Mon-Fri",
        voicemail: "No",
        email: "lg.ibd@nhs.net",
        responseTime: "Within 24 hours during working week",
        address: "High Street\nLewisham\nSE13 6LH",
        healthBoard: "Lewisham and Greenwich NHS Trust"
    },
    {
        name: "University Hospital of North Durham",
        phone: "01913 332995",
        hours: "2pm - 4pm Mon and Thurs, 10am - 12pm Tues, Wed, and Fri",
        voicemail: "Yes",
        email: "cddft.ibdnurses@nhs.net",
        responseTime: "Within 48 hours",
        address: "North Road, Durham\nDH1 5TW",
        healthBoard: "County Durham and Darlington NHS Foundation Trust"
    },
    {
        name: "University Hospital of North Tees",
        phone: "01642 624981",
        hours: "08.30 - 16.30",
        voicemail: "Yes",
        email: "nth-tr.ibd@nhs.net",
        responseTime: "Within 24 hours",
        address: "North Tees General Hospital\nHardwick Road\nStockton-on-Tees\nTS19 8PE",
        healthBoard: "North Tees and Hartlepool NHS Trust"
    },
    {
        name: "Warrington Hospital",
        phone: "01925 662895",
        hours: "8am - 4pm Mon-Fri",
        voicemail: "Yes",
        email: "whh.ibdnurses@nhs.net",
        responseTime: "Up to 1 week",
        address: "Warrington and Halton Hospital\nLovely Lane\nWarrington\nWA5 1QG",
        healthBoard: "Warrington and Halton NHS Trust"
    },
    {
        name: "Watford General Hospital",
        phone: "01442 287485",
        hours: "24 hours voicemail",
        voicemail: "Yes",
        email: "westherts.ibdnurses@nhs.net",
        responseTime: "Within 48 hours, excluding weekends",
        address: "Vicarage Road\nWD18 0HB",
        healthBoard: "West Hertfordshire Hospital NHS"
    },
    {
        name: "Weston General Hospital, Weston-Super-Mare",
        phone: "01934 881254",
        hours: "24 hour voicemail",
        voicemail: "Yes",
        email: "theresatindall@nhs.net",
        responseTime: "Within 2 working days",
        address: "Grange Road\nBS23 4TQ",
        healthBoard: "Weston Area Health Trust"
    },
    {
        name: "Whiston Hospital",
        phone: "0151 478 7776",
        hours: "Mon, Tues, Weds 9am-12pm; Thurs 8am-10am; Fri 9am-11:30am",
        voicemail: "No",
        email: "ibd@sthk.nhs.uk",
        responseTime: "Not specified",
        address: "Warrington Road\nPrescot\nLiverpool\nL35 5DR",
        healthBoard: "St Helens and Knowsley Teaching Hospitals NHS Trust"
    },
    {
        name: "Withybush General Hospital",
        phone: "01437 773571",
        hours: "8am - 4pm",
        voicemail: "Yes",
        email: "kerrie.johns@wales.nhs.uk",
        responseTime: "By end of the next working day",
        address: "Fishguard Road, Haverfordwest\nSA61 2PZ",
        healthBoard: "Hywel Dda Health Board"
    },
    {
        name: "Wythenshawe Hospital",
        phone: "0161 998 7070",
        hours: "8am - 6pm",
        voicemail: "No",
        email: "ibdflare@mft.nhs.uk",
        responseTime: "24-48 hours",
        address: "Wythenshawe Hospital\nSouthmoor Road\nM23 9LT",
        healthBoard: "Manchester University NHS Foundation Trust"
    },
    {
        name: "Yeovil District Hospital",
        phone: "01935 384728",
        hours: "9am - 5pm, Mon - Fri",
        voicemail: "No",
        email: "ibdadviceline@ydh.nhs.uk",
        responseTime: "Not specified",
        address: "Higher Kingston, Yeovil\nBA21 4AT",
        healthBoard: "Yeovil District Hospital NHS Foundation Trust"
    },
    {
        name: "Test Hospital",
        phone: "07392 034518",
        hours: "24/7",
        voicemail: "N/A",
        email: "alisidat1@gmail.com",
        responseTime: "Instant",
        address: "123 Test Road, Testington, TE45 67S",
        healthBoard: "N/A"
    },
];
