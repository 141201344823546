import React from 'react';
import { Link } from 'react-router-dom';

const ServicesSection: React.FC = () => {
  const services = [
    { to: '/', imgSrc: '/chatbot.png', alt: 'AI Chat Bot', label: 'AI Chat Bot' },
    { to: '/find-charity-support', imgSrc: '/healthcare.png', alt: 'Find Charity Support', label: 'Find Charity Support' },
    { to: '/nearby-services', imgSrc: '/nearby.png', alt: 'Nearby Services', label: 'Nearby Services' },
    { to: '/medication-information', imgSrc: '/drugs.png', alt: 'Medication Information', label: 'Medication Information' }
  ];

  return (
    <div className='bg-white'>
      <div className='w-[90%] md:w-[80%] mx-auto h-fit py-20'>
        <div className='text-center tracking-widest font-medium ml-1 text-[#00a7cc]'>OUR SERVICES</div>
        <div className='text-center font-bold text-5xl'>Why Choose Us?</div>
        <div className='tracking-wide ml-1 mt-5 mb-12 text-center'>
        ThePatientCentre.com connects patients to healthcare resources, charities, and nearby services like pharmacists and opticians, while offering reliable information on conditions, treatments, and medications.
        </div>
        <div className='flex flex-wrap gap-4 justify-center md:justify-between'>
          {services.map((service, index) => (
            <Link key={index} to={service.to}>
              <div className='w-44 h-44 rounded-full bg-slate-200 flex justify-center items-center shadow-lg'>
                <img src={service.imgSrc} alt={service.alt} className='w-auto h-20' />
              </div>
              <div className='mt-4 font-bold text-center'>{service.label}</div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ServicesSection;
