import React, { ChangeEvent, useState } from 'react';
import axios from 'axios';
import Layout from '../../Shared/Layout';
import { FaMapMarkerAlt } from 'react-icons/fa';

const categoryOptions: { name: string; label: string }[] = [
  { name: 'Pharmacy', label: 'Pharmacy' },
  { name: 'Dentist', label: 'Dentist' },
  { name: 'Opticians', label: 'Opticians' },
];

const Nearby: React.FC = () => {
  const [postalCode, setPostalCode] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(10);
  const [loadMoreVisible, setLoadMoreVisible] = useState<boolean>(false);
  const [servicesData, setServicesData] = useState<any[]>([]);

  const handlePostalCodeChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPostalCode(value);

    if (value.length >= 3) {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://api.postcodes.io/postcodes/${value}/autocomplete?limit=${limit}`
        );
        const results = response.data.result || [];
        setSuggestions(results);
        setLoadMoreVisible(results.length === limit);
      } catch (error) {
        console.error('Error fetching postal code suggestions', error);
      }
      setLoading(false);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    setPostalCode(suggestion);
    setSuggestions([]);
  };

  const handleLoadMore = async () => {
    const newLimit = limit + 10;
    setLimit(newLimit);
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.postcodes.io/postcodes/${postalCode}/autocomplete?limit=${newLimit}`
      );
      const results = response.data.result || [];
      setSuggestions(results);
      setLoadMoreVisible(results.length === newLimit);
    } catch (error) {
      console.error('Error loading more suggestions', error);
    }
    setLoading(false);
  };

  const handleSearch = async () => {
    if (!postalCode || !selectedCategory) {
      alert('Please provide a postal code and select a category.');
      return;
    }

    const sanitizedPostalCode = postalCode.replace(/\s+/g, '').toLowerCase();

    try {
      setLoading(true);
      const response = await axios.get(
        `https://tpcnearbyservices-f6cmgrckhreffghj.ukwest-01.azurewebsites.net/nearby-services`,
        {
          params: {
            postcode: sanitizedPostalCode,
            category: selectedCategory,
            limit,
          },
        }
      );
      console.log('API Response:', response.data);
      setServicesData(response.data); // Store the data in state
    } catch (error) {
      console.error('Error fetching nearby services:', error);
      setServicesData([]); // Clear data if error
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className='bg-[#c5e3ec] min-h-screen'>
        <div className='py-20 mx-auto w-[80%]'>
          <div className='font-bold text-4xl md:text-6xl mb-6 text-center'>Nearby Services</div>
          <div className='flex flex-col md:flex-row gap-6 relative'>
            <div className='flex-1 bg-white py-2 px-6 rounded-lg relative'>
              <input
                type='text'
                placeholder='Postal Code'
                className='border-none outline-none h-full w-full'
                maxLength={8}
                value={postalCode}
                onChange={handlePostalCodeChange}
              />
              {suggestions.length > 0 && (
                <ul className='absolute max-h-80 overflow-auto top-full left-0 w-full bg-white border border-gray-300 rounded-lg mt-1 z-10'>
                  {loading ? (
                    <li className='py-2 px-4'>Loading...</li>
                  ) : (
                    suggestions.map((suggestion: string, index) => (
                      <li
                        key={index}
                        className='py-2 px-4 cursor-pointer hover:bg-gray-100'
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {suggestion}
                      </li>
                    ))
                  )}

                  {loadMoreVisible && !loading && (
                    <li
                      className='py-2 px-4 text-[#00a7cc] cursor-pointer'
                      onClick={handleLoadMore}
                    >
                      Load More
                    </li>
                  )}
                </ul>
              )}
            </div>
            <div
              className='bg-white py-2 px-6 rounded-lg cursor-pointer flex justify-center items-center'
              onClick={handleSearch}
            >
              Search
            </div>
          </div>
          <div className='flex gap-6 mt-4 flex-wrap'>
            {categoryOptions.map((category, index) => (
              <div key={index} className='flex gap-2 items-center'>
                <input
                  type='radio'
                  name='category'
                  className='bg-white'
                  value={category.name}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                />
                <label>{category.label}</label>
              </div>
            ))}
          </div>

          {loading && <div className='text-center mt-10'>Loading...</div>}

          {!loading && servicesData.length === 0 && (
            <div className='bg-white min-h-44 mt-6 rounded-lg flex justify-center items-center p-4'>
              <div>No Item Available</div>
            </div>
          )}

          {!loading && servicesData.length > 0 && (
            <div className='mt-10'>
              <h2 className='text-2xl font-semibold mb-4'>
                Nearby {selectedCategory}s:
              </h2>
              <div className='flex flex-col space-y-6'>
                {servicesData.map((item, index) => {
                  const googleMapUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(item.address)}`;
                  // Use coordinates for embedding a map location
                  // This iframe doesn't need an API key, just uses q=lat,lng
                  const embedUrl = `https://www.google.com/maps?q=${item.latitude},${item.longitude}&output=embed`;

                  return (
                    <div
                      key={index}
                      className='flex bg-white rounded-lg shadow hover:shadow-lg transition-shadow p-4'
                    >
                      <div className='w-full md:w-1/2'>
                        <div className='flex items-center space-x-4 mb-4'>
                          <img
                            src={item.image_url}
                            alt={item.name}
                            className='w-16 h-16 object-cover rounded-full'
                          />
                          <div>
                            <h3 className='text-xl font-semibold'>{item.name}</h3>
                            <p className='text-gray-500'>{item.category}</p>
                          </div>
                        </div>
                        <div className='text-gray-700 text-sm mb-4'>
                          <p className='mb-2'>
                            <span className='font-semibold'>Phone:</span> {item.phone_number}
                          </p>
                          <p className='mb-2'>
                            <span className='font-semibold'>NHS Policy:</span>{' '}
                            {item.nhs_patient_policy || 'No information'}
                          </p>
                          <p className='mb-2'>
                            <span className='font-semibold'>Address:</span> {item.address}
                          </p>
                          <p className='mb-2'>
                            <span className='font-semibold'>Town:</span> {item.town}
                          </p>
                          <p className='mb-2'>
                            <span className='font-semibold'>Postcode:</span> {item.postcode}
                          </p>
                        </div>
                      </div>
                      {/* On mobile: just an icon linking to Google Maps */}
                      <a
                        href={googleMapUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='md:hidden text-blue-600 hover:underline flex items-center space-x-2'
                      >
                        <FaMapMarkerAlt className='text-2xl' />
                      </a>

                      {/* On desktop: embedded map */}
                      <div className='hidden md:block w-1/2 h-64 mt-4'>
                        <iframe
                          className='w-full h-full border-0'
                          src={embedUrl}
                          loading='lazy'
                          referrerPolicy='no-referrer-when-downgrade'
                          title={`${item.name} map`}
                        ></iframe>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Nearby;