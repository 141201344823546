import React, { useState, useRef } from 'react';
import Layout from '../../Shared/Layout';
import { hospitalData } from '../../data';
import {
    FaMicrophone,
    FaPause,
    FaPlay,
    FaStop,
    FaSpinner,
    FaUndo
} from 'react-icons/fa';

interface Hospital {
    name: string;
    phone: string;
    hours: string;
    email: string;
    responseTime: string;
    address: string;
    healthBoard: string;
}

const IBDHelp: React.FC = () => {
    const [selectedHospital, setSelectedHospital] = useState<Hospital | null>(null);
    const [email, setEmail] = useState<string>('');
    const [generatedText, setGeneratedText] = useState<string>('');
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [isPaused, setIsPaused] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const audioChunksRef = useRef<Blob[]>([]);

    const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const hospital = hospitalData.find(hospital => hospital.name === e.target.value) || null;
        setSelectedHospital(hospital);
    };

    const startRecording = () => {
        setIsRecording(true);
        setIsPaused(false);
        audioChunksRef.current = [];

        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                const mediaRecorder = new MediaRecorder(stream);
                mediaRecorderRef.current = mediaRecorder;

                mediaRecorder.ondataavailable = (event) => {
                    audioChunksRef.current.push(event.data);
                };

                mediaRecorder.onstop = () => {
                    const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                    handleGenerateText(audioBlob);
                };

                mediaRecorder.start();
            })
            .catch((error) => {
                console.error("Error accessing microphone:", error);
                alert("Unable to access microphone. Please check your browser settings.");
                setIsRecording(false);
            });
    };

    const pauseRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
            mediaRecorderRef.current.pause();
            setIsPaused(true);
        }
    };

    const resumeRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === "paused") {
            mediaRecorderRef.current.resume();
            setIsPaused(false);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
        }
    };

    async function convertAudioToMono(file: File | Blob): Promise<Blob> {
        const audioContext = new AudioContext({ sampleRate: 16000 });
        const arrayBuffer = await file.arrayBuffer();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

        // Create offline context for processing
        const offlineContext = new OfflineAudioContext(1, audioBuffer.length, 16000);
        const source = offlineContext.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(offlineContext.destination);
        source.start();

        // Render audio
        const renderedBuffer = await offlineContext.startRendering();

        // Convert to WAV format
        const length = renderedBuffer.length * 2;
        const buffer = new ArrayBuffer(44 + length);
        const view = new DataView(buffer);

        // WAV header
        const writeString = (view: DataView, offset: number, string: string) => {
            for (let i = 0; i < string.length; i++) {
                view.setUint8(offset + i, string.charCodeAt(i));
            }
        };

        writeString(view, 0, "RIFF");
        view.setUint32(4, 36 + length, true);
        writeString(view, 8, "WAVE");
        writeString(view, 12, "fmt ");
        view.setUint32(16, 16, true);
        view.setUint16(20, 1, true);
        view.setUint16(22, 1, true);
        view.setUint32(24, 16000, true);
        view.setUint32(28, 32000, true);
        view.setUint16(32, 2, true);
        view.setUint16(34, 16, true);
        writeString(view, 36, "data");
        view.setUint32(40, length, true);

        // Write audio data
        const data = new Float32Array(renderedBuffer.getChannelData(0));
        let offset = 44;
        for (let i = 0; i < data.length; i++) {
            const sample = Math.max(-1, Math.min(1, data[i]));
            view.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7fff, true);
            offset += 2;
        }

        return new Blob([buffer], { type: "audio/wav" });
    }

    const handleGenerateText = async (audioBlob: Blob) => {
        if (!audioBlob) {
            alert('Please record audio first');
            return;
        }

        const formData = new FormData();
        const transformedBlob = await convertAudioToMono(audioBlob);
        formData.append('audio_file', transformedBlob, 'audio.wav');

        try {
            setIsLoading(true);
            const response = await fetch(`https://tpcibdtransciber-gzcqa2ahauehascx.ukwest-01.azurewebsites.net/transcribe`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('API Error:', errorData);
                const errorMessage = errorData.detail
                    ? errorData.detail.map((err: any) => err.msg).join(', ')
                    : 'Error transcribing audio';
                setGeneratedText(prevText => prevText ? `${prevText}\nError: ${errorMessage}` : `Error: ${errorMessage}`);
                return;
            }

            const data = await response.json();

            if (data.text) {
                setGeneratedText(prevText => {
                    return prevText ? `${prevText}\n${data.text}` : data.text;
                });
            } else {
                setGeneratedText(prevText => prevText ? `${prevText}\nError: No text returned.` : 'Error: No text returned.');
            }
        } catch (error) {
            console.error('Request failed', error);
            setGeneratedText(prevText => prevText ? `${prevText}\nRequest failed` : 'Request failed');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async () => {
        if (!email.trim()) {
            alert("Please enter your email.");
            return;
        }

        if (!selectedHospital) {
            alert("Please select a hospital.");
            return;
        }

        if (!generatedText.trim()) {
            alert("Please generate or enter some text.");
            return;
        }

        const payload = {
            destination_emails: [selectedHospital.email, email],
            subject: "Test",
            html_content: `Patient Email: ${email}
Transcribed Text: ${generatedText}`
        };

        try {
            const response = await fetch(`https://tpcemailsender-hwcubdchb3dhhtap.ukwest-01.azurewebsites.net/send-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                alert("Data submitted successfully!");
            } else {
                const errorData = await response.json();
                alert(`Submission failed: ${errorData.message || 'Unknown error.'}`);
            }
        } catch (error) {
            console.error("Submission error:", error);
            alert("An error occurred during submission.");
        }
    };

    const handleReset = () => {
        if (window.confirm("Are you sure you want to reset the generated text?")) {
            setGeneratedText('');
        }
    };

    return (
        <Layout>
            <div>
                <div className="py-20 mx-auto w-[90%] md:w-[80%] relative">
                    {/* Hospital Selection */}
                    <div className="flex flex-row space-x-4 mb-4">
                        <select
                            className="p-2 border rounded-md"
                            onChange={handleSelect}
                            value={selectedHospital?.name || ''}
                            required
                        >
                            <option value="">Select IBD Trust...</option>
                            {hospitalData.map((hospital, index) => (
                                <option key={index} value={hospital.name}>
                                    {hospital.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Selected Hospital Details */}
                    {selectedHospital && (
                        <div className="mb-4 p-4 border rounded-md">
                            <div>
                                <strong className="block text-lg">{selectedHospital.name}</strong>
                                <p><strong>Phone:</strong> {selectedHospital.phone}</p>
                                <p><strong>Hours:</strong> {selectedHospital.hours}</p>
                                <p><strong>Email:</strong> {selectedHospital.email}</p>
                                <p><strong>Response Time:</strong> {selectedHospital.responseTime}</p>
                                <p><strong>Address:</strong> {selectedHospital.address}</p>
                                <p><strong>Health Board:</strong> {selectedHospital.healthBoard}</p>
                            </div>
                        </div>
                    )}

                    {/* Email Input */}
                    <div className="mb-4">
                        <label className="block mb-2">Your Email</label>
                        <input
                            type="email"
                            className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            required
                        />
                    </div>

                    {/* Instructions */}
                    <div className="mb-4 text-gray-700">
                        <p>
                            Please click on the <strong>"Start Recording"</strong> button below and speak into your microphone.
                            Your speech will be transcribed into text.
                        </p>
                        <p><strong>Note:</strong> The transcription process will start automatically when you stop the recording.</p>
                    </div>

                    {/* Recording Control Buttons with Icons */}
                    <div className="flex space-x-4 mb-4">
                        {/* Start Recording Button */}
                        <button
                            title="Start Recording"
                            className={`flex items-center px-4 py-2 bg-[#00a7cc] text-white rounded-md 
                                       ${isRecording ? 'bg-gray-400 cursor-not-allowed' : 'hover:bg-[#009bb3]'} 
                                       transition-colors duration-200`}
                            onClick={startRecording}
                            disabled={isRecording}
                        >
                            <FaMicrophone className="mr-2" />
                            Start
                        </button>

                        {/* Pause Recording Button */}
                        {isRecording && !isPaused && (
                            <button
                                title="Pause Recording"
                                className="flex items-center px-4 py-2 bg-yellow-500 text-white rounded-md 
                                           hover:bg-yellow-600 transition-colors duration-200"
                                onClick={pauseRecording}
                            >
                                <FaPause className="mr-2" />
                                Pause
                            </button>
                        )}

                        {/* Resume Recording Button */}
                        {isRecording && isPaused && (
                            <button
                                title="Resume Recording"
                                className="flex items-center px-4 py-2 bg-green-500 text-white rounded-md 
                                           hover:bg-green-600 transition-colors duration-200"
                                onClick={resumeRecording}
                            >
                                <FaPlay className="mr-2" />
                                Resume
                            </button>
                        )}

                        {/* Stop Recording Button */}
                        {isRecording && (
                            <button
                                title="Stop Recording"
                                className="flex items-center px-4 py-2 bg-red-600 text-white rounded-md 
                                           hover:bg-red-700 transition-colors duration-200"
                                onClick={stopRecording}
                            >
                                <FaStop className="mr-2" />
                                Stop
                            </button>
                        )}
                    </div>

                    {/* Generated Textarea */}
                    <div className="mb-4">
                        <label className="block mb-2">Generated Text</label>
                        <textarea
                            className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={generatedText}
                            onChange={(e) => setGeneratedText(e.target.value)}
                            rows={6}
                            placeholder="Name, DOB, hospital number if available.
                                        - Time - how long have issues been going on for
                                        - Bowel movements per day
                                        - Blood in stool
                                        - Temperature
                                        - Recent hospital admission/surgery
                                        - Recent changes in medication
                                        - Consultant name
                                        - Any new changes, i.e., abscesses, fistulas, abdominal lumps"
                        />
                        {/* Loader Indicator */}
                        {isLoading && (
                            <div className="flex items-center mt-2">
                                <FaSpinner className="mr-2 animate-spin text-blue-500" />
                                <span className="text-gray-700">Transcribing...</span>
                            </div>
                        )}
                    </div>

                    {/* Action Buttons */}
                    <div className="flex space-x-4">
                        {/* Send Button */}
                        <button
                            className={`px-4 py-2 bg-[#00a7cc] text-white rounded-md hover:bg-[#009bb3] 
                                       transition-colors duration-200 
                                       ${isLoading || !generatedText.trim() ? 'opacity-50 cursor-not-allowed' : ''}`}
                            onClick={handleSubmit}
                            disabled={isLoading || !generatedText.trim()}
                        >
                            Send
                        </button>

                        {/* Reset Button */}
                        <button
                            className={`flex items-center px-4 py-2 bg-gray-500 text-white rounded-md 
                                       hover:bg-gray-600 transition-colors duration-200 
                                       ${!generatedText.trim() ? 'hidden' : ''}`}
                            onClick={handleReset}
                            disabled={isLoading || !generatedText.trim()}
                            title="Reset Generated Text"
                        >
                            <FaUndo className="mr-2" />
                            Reset
                        </button>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default IBDHelp;
