import React, { useState, useEffect } from 'react';
import Select, { SingleValue } from 'react-select';
import CharitySupportCard from '../CharitySupportCard';

interface ConditionOption {
    value: string;
    label: string;
}

export interface CharitySupportData {
    image?: string;
    condition: string;
    charity_name: string;
    nhs_guidance: string;
    national_charity_link: string;
    safety_netting_advice: string;
}

const CharitySupport = () => {
    const [selectedCondition, setSelectedCondition] = useState<SingleValue<ConditionOption>>({ value: 'all', label: 'All' });
    const [charityData, setCharityData] = useState<CharitySupportData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [medicalConditions, setMedicalConditions] = useState<ConditionOption[]>([]);

    useEffect(() => {
        fetchCharitySupportData();
    }, []);

    const fetchCharitySupportData = async () => {
        try {
            const response = await fetch('https://tpc.bigberri.com/wp-json/charity-support/v1/items');
            if (!response.ok) {
                throw new Error('Error fetching data');
            }
            const data: CharitySupportData[] = await response.json();
            setCharityData(data);

            const conditionOptions = [
                { value: 'all', label: 'All' },
                ...data.map((item) => ({
                    value: item.condition.toLowerCase(),
                    label: item.condition,
                })),
            ];

            setMedicalConditions(conditionOptions);
        } catch (error) {
            setError('Failed to fetch charity support data');
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (selectedOption: SingleValue<ConditionOption>) => {
        setSelectedCondition(selectedOption);
    };

    const filteredCharityData = selectedCondition?.value === 'all'
        ? charityData
        : charityData.filter((item) => item.condition.toLowerCase() === selectedCondition?.value);

    return (
        <div className="bg-[#c5e3ec]">
            <div className="py-20 mx-auto w-[90%] md:w-[80%]">
                <div className="font-bold text-4xl md:text-6xl mb-6 text-center">
                    Find Charity Support
                </div>
                <div className="flex gap-6">
                    <div className="flex-1 rounded-lg">
                        <Select
                            value={selectedCondition}
                            onChange={handleChange}
                            options={medicalConditions}
                            placeholder="Select a medical condition"
                            isSearchable={true}
                        />
                    </div>
                    <div className="bg-white py-2 px-6 rounded-lg" onClick={() => alert(`Searching for: ${selectedCondition?.label || 'None'}`)}>
                        Search
                    </div>
                </div>
                <div className="mt-6">
                    {isLoading ? (
                        <div>Loading...</div>
                    ) : error ? (
                        <div>{error}</div>
                    ) : (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {filteredCharityData.length > 0 ? (
                                filteredCharityData.map((item, idx) => (
                                    <CharitySupportCard key={idx} data={item} />
                                ))
                            ) : (
                                <div>No Items Available</div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CharitySupport;